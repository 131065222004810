<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p v-if="groupUpdating" class="modal-card-title">
              Atualizar informações do grupo
            </p>
            <p v-else class="modal-card-title">Insira o grupo a ser inserido</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Nome">
              <b-input type="text" v-model="name" placeholder="Nome" required>
              </b-input>
            </b-field>
            <b-field label="Adicionar contato">
              <multiselect
                v-model="selectedContact"
                :multiple="false"
                :options="getContacts"
                label="name"
                track-by="id"
              >
                <template slot="placeholder">Contatos</template>
              </multiselect>
              <button
                class="button is-primary"
                type="button"
                :disabled="!selectedContact"
                @click="selectContact"
              >
                <b-icon icon="account-plus" size="" />
              </button>
            </b-field>
            <b-field label="Contatos selecionados">
              <div>
                <div v-if="selectedContacts.length > 0">
                  <b-table
                    :data="selectedContacts"
                    :bordered="true"
                    :striped="false"
                    :narrowed="false"
                    :hoverable="false"
                    :loading="false"
                    :focusable="false"
                    :mobile-cards="true"
                    paginated
                    :per-page="5"
                    pagination-position="bottom"
                  >
                    <b-table-column
                      :numeric="true"
                      field="id"
                      label="ID"
                      v-slot="props"
                    >
                      {{ props.row.id }}
                    </b-table-column>

                    <b-table-column field="name" label="Nome" v-slot="props">
                      {{ props.row.name }}
                    </b-table-column>
                    <b-table-column
                      field="delete"
                      width="200"
                      label="Remover"
                      v-slot="props"
                    >
                      <b-button
                        type="is-danger"
                        @click="removeContact(props.row)"
                        >Remover</b-button
                      >
                    </b-table-column>
                  </b-table>
                </div>
                <div v-else>
                  <p>Não há contatos selecinados</p>
                </div>
              </div>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
            <button
              v-if="!!groupUpdating"
              class="button is-primary"
              @click="updateContact"
            >
              Atualizar
            </button>
            <button v-else class="button is-primary" @click="addContactGroup">
              Gravar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import multiselect from 'vue-multiselect'

export default {
  name: 'ModalInsertContactGroup',
  components: {
    multiselect
  },
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    contacts: {
      type: Array,
      required: true
    },
    groupUpdating: {
      type: Object,
      required: false
    }
  },
  created () {
    if (this.groupUpdating) {
      this.name = this.groupUpdating.name
      this.selectedContacts = this.groupUpdating.contacts_groups_contacts.map(
        (groupContact) => {
          const c = groupContact.contact
          c.name = `${c.name} - (${c.phone})`
          return c
        }
      )
    }
  },
  data () {
    return {
      name: '',
      selectedContacts: [],
      selectedContact: ''
    }
  },
  computed: {
    getContacts () {
      return this.contacts.map((contact) => {
        const c = contact
        c.code = c.id
        c.name = `${c.name} - (${c.phone})`
        return c
      })
    }
  },
  methods: {
    addContactGroup () {
      this.$emit('addContactGroup', {
        name: this.name,
        contacts: this.selectedContacts
      })
    },
    closeModal () {
      this.$emit('closeModal')
    },
    selectContact () {
      if (this.selectedContacts.find(contact => contact.id === this.selectedContact.id)) return
      this.selectedContacts.unshift(this.contacts.find(contact => contact.id === this.selectedContact.id))
    },
    updateContact () {
      if (this.name && this.name.length >= 0 && this.name.length <= 3) {
        this.$buefy.dialog.alert({
          title: 'Nome muito curto',
          message:
            'O <b>nome</b> informado é inválido. O novo nome deve ter no mínimo 2 caracteres!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      this.emitUpdateContactGroup({
        id: this.groupUpdating.id,
        name: this.name,
        contacts: this.selectedContacts
      })
    },
    removeContact (contact) {
      this.selectedContacts = this.selectedContacts.filter(c => c.id !== contact.id)
    },
    emitUpdateContactGroup (contact) {
      this.$emit('updateContactGroup', contact)
    }
  }
}
</script>

<style scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>
