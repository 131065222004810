<template>
  <div>
    <section>
      <b-modal :active="isModalConfirmActive" @close="closeModal" :width="600">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">
              <b>Sincronizar contatos do WhatsApp</b>
            </p>
          </header>
          <section class="modal-card-body modal-confirm-sync">
            <div>
              <b-field label="Número">
                <multiselect
                  v-model="wwPhones"
                  :multiple="true"
                  :options="getPhones"
                  label="name"
                  disabledProp="disabled"
                  track-by="phone"
                  select-label=""
                  deselect-label=""
                >
                  <template slot="placeholder">Números</template>
                </multiselect>
              </b-field>
              <br>
              <b-checkbox v-model="overlap">Sobrepor contatos repetidos</b-checkbox>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">Cancelar</button>
            <button class="button is-primary" @click="synchronize">Sincronizar</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'
import { API_TYPE } from '../../utils/constants'

export default {
  name: 'ModalConfirmSyncWppContacts',
  components: {
    multiselect
  },
  props: {
    isModalConfirmActive: {
      type: Boolean,
      required: true
    },
    settings: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      wwPhones: [],
      overlap: true
    }
  },
  computed: {
    getPhones () {
      const phones = []
      for (const s of this.settings) {
        if (s.api_type === API_TYPE.OFICIAL) continue
        phones.push({ phone: s.phone, name: s.name, state: s.state })
      }
      return phones.map((phone, index) => {
        const c = phone
        c.code = index + 1
        c.id = index++
        c.$isDisabled = c.state !== 'CONNECTED'
        c.name = c.name ? c.name + ' - ' + c.phone : c.phone
        return c
      })
    }
  },
  methods: {
    synchronize () {
      this.$emit('sync', { canOverlab: this.overlap, phones: this.wwPhones.map(p => p.phone) })
      this.$emit('closeModal')
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
.modal-confirm-sync {
  height: 30vh;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.modal-confirm-sync>div {
  display: inline-block;
  width: 100%;
}

.modal-card-foot {
  justify-content: flex-end !important;
}
</style>
