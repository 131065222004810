<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal" :width="650">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title"><b>Exclui contado duplicado</b></p>
          </header>
          <section class="modal-card-body">
            <p>Foi encontrado outro contato com o número {{ contact.phone }}:</p>
            <br>
            <div class="contact-cards">
              <div class="card contact-card">
                <div class="card-content">
                  <div class="content">
                    <p><b>Contato selecionado</b> <br><br>
                    <b>ID</b>: {{ contact.id }} <br>
                    <b>Nome</b>: {{ contact.name }} <br>
                    <b>Telefone</b>: {{ contact.phone }}</p>
                  </div>
                </div>
              </div>
              <div class="card contact-card">
                <div class="card-content">
                  <div class="content">
                    <p><b>Contato duplicado</b> <br><br>
                    <b>ID</b>: {{ duplicate.id }} <br>
                    <b>Nome</b>: {{ duplicate.name }} <br>
                    <b>Telefone</b>: {{ duplicate.phone }}</p>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <p>Deseja que os tickets do contato <em>{{ contact.id }} - {{ contact.name }}</em> sejam <b>transferidos</b> para o contato <em>{{ duplicate.id }} - {{ duplicate.name }}</em>?</p>
          </section>
          <footer class="modal-card-foot">
            <div class="buttons">
              <button class="button delete-button is-danger" type="button" @click="deleteContact(contact)">
                Excluir o contato e seus tickets
              </button>
              <button class="button delete-both-button is-success" type="button" @click="deleteContact(contact.id, duplicate.id)">
                Transferir os tickets e excluir o contato
              </button>
            </div>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirmContactDelete',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    contact: {
      type: Object,
      required: true
    },
    duplicate: {
      type: Object,
      required: true
    }
  },
  methods: {
    deleteContact (contact, duplicateId) {
      if (duplicateId) {
        this.$emit('deleteContact', { id: contact, transferTo: duplicateId })
      } else {
        this.$emit('deleteContact', contact)
      }
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
  .contact-cards {
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 15px;
  }

  .contact-card {
    width: 50%;
    display: table-cell;
  }

  .modal-card-foot {
    display: block !important;
  }

  .modal-card-foot .buttons {
    margin-right: auto;
    text-align: center;
    display: block;
  }

 .modal-card-foot button {
    margin-right: 0 auto;
    display: inline-block;
  }

</style>
