<template>
  <div>
    <div v-if="allContactsGroups.length > 0">
      <b-table
        :data="allContactsGroups"
        :bordered="true"
        :striped="true"
        :narrowed="false"
        :hoverable="false"
        :loading="false"
        :focusable="false"
        :mobile-cards="true"
        paginated>

        <b-table-column :numeric="true" field="id" label="ID" v-slot="props">
          {{ props.row.id }}
        </b-table-column>

        <b-table-column searchable field="name" label="Nome" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="update_delete" label="" v-slot="props">
          <dropdown-tag :isDeleting="groupDeletingId === props.row.id">
            <dropdown-item-tag @click="updateContact(props.row)">Atualizar</dropdown-item-tag>
            <dropdown-item-tag @click="confirmContactDelete(props.row)">Excluir</dropdown-item-tag>
          </dropdown-tag>
        </b-table-column>

      </b-table>
    </div>
  </div>
</template>

<script>

import DropdownTag from '../commons/DropdownTag.vue'
import DropdownItemTag from '../commons/DropdownItemTag.vue'

export default {
  name: 'ContactsGroupsList',
  props: {
    contactsGroups: {
      type: Array,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    },
    groupDeletingId: {
      type: Number,
      required: true
    }
  },
  components: {
    DropdownTag,
    DropdownItemTag
  },
  computed: {
    allContactsGroups: {
      get () {
        return this.contactsGroups
      },
      set () {
        // do nothing
      }
    }
  },
  methods: {
    confirmContactDelete (contact) {
      this.$buefy.dialog.confirm({
        title: 'Excluir grupo de contatos',
        message: 'Deseja <b>excluir</b> este grupo de contato?',
        confirmText: 'Excluir grupo',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('removeContactGroup', contact)
      })
    },
    updateContact (contact) {
      this.$emit('updateContactGroup', contact)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
