<template>
  <section>
    <b-modal :active="isModalTableActive" @close="closeModal" :width="1500">
      <div class="modal-card" style="width: 2000px;" size="is-large">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <b>Erros da planilha:</b>
          </p>
        </header>
        <card-component title="" class="has-table has-mobile-sort-spaced">
          <b-table
            :data="getData"
            :bordered="false"
            :striped="true"
            :narrowed="false"
            :hoverable="true"
            :loading="false"
            :focusable="false"
            :mobile-cards="true"
            :per-page="10"
            detailed
            :paginated="true"
          >
            <b-table-column v-slot="props" label="Linha" field="index">
              {{ props.row.index + 1 }}
            </b-table-column>
            <b-table-column v-slot="props" label="ID" field="id">
              {{ props.row.id }}
            </b-table-column>
            <b-table-column v-slot="props" label="Nome" field="name">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props" label="Telefone" field="phone">
              {{ props.row.phone }}
            </b-table-column>
            <b-table-column v-slot="props" label="CPF ou CNPJ" field="cpf_cpnj">
              {{ props.row.cpf_cpnj }}
            </b-table-column>
            <b-table-column v-slot="props" label="Data de aniversário" field="birthday" >
              {{ props.row.birthday }}
            </b-table-column>
            <b-table-column v-slot="props" label="E-mail" field="e-mail">
              {{ props.row.email }}
            </b-table-column>
            <b-table-column v-slot="props" label="Instagram" field="instagram_profile">
              {{ props.row.instagram_profile }}
            </b-table-column>
            <b-table-column v-slot="props" label="Endereço" field="address">
              {{ props.row.address }}
            </b-table-column>
            <b-table-column v-slot="props" label="Complemento" field="address2">
              {{ props.row.address2 }}
            </b-table-column>
            <b-table-column v-slot="props" label="Cidade" field="city">
              {{ props.row.city }}
            </b-table-column>
            <b-table-column v-slot="props" label="Estado" field="district">
              {{ props.row.state }}
            </b-table-column>
            <b-table-column v-slot="props" label="Informações adicionais" field="add_info">
              {{ props.row.additional_Information }}
            </b-table-column>
            <b-table-column v-slot="props" label="Tags" field="tags">
              {{ props.row.contacts_tags }}
            </b-table-column>
            <b-table-column v-slot="props" label="Grupo de contatos" field="contacts_groups">
              {{ props.row.contacts_groups }}
            </b-table-column>
            <b-table-column  v-slot="props" label="Data de cadastro" field="date_create">
              {{ props.row.date_create }}
            </b-table-column>
            <b-table-column v-slot="props" label="Tipo de cadastro"  field="type_create">
              {{ props.row.type_create }}
            </b-table-column>
            <b-table-column v-slot="props" label="Encarteiramento"  field="portfolio">
              {{ props.row.portfolio }}
            </b-table-column>
            <template #detail="props">
              <span class="box has-text-danger">
                {{ props.row.errors.join(', ') }}
              </span>
            </template>
          </b-table>
        </card-component>

        <footer class="modal-card-foot">
          <button  class="button is-pulled-right"  type="button"  @click="closeModal"  >
            Fechar
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: 'ModalTableContactsCSV',
  data() {
    return {
      errorsData: [],
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '40',
          numeric: true,
        },
        {
          field: 'name',
          label: 'Nome',
        },
        {
          field: 'cpf_cpnj',
          label: 'CPF ou CNPJ',
        },
        {
          field: 'birthday',
          label: 'Data de aniversário',
        },
        {
          field: 'type_contact',
          label: 'Tipo de Contato',
        },
        {
          field: 'e-mail',
          label: 'Email',
        },
        {
          field: 'instagram_profile',
          label: 'Instagram',
        },
        {
          field: 'address',
          label: 'Endereço',
        },
        {
          field: 'address2',
          label: 'Tipo de Contato',
        },
        {
          field: 'neighborhood',
          label: 'Bairro',
        },
        {
          field: 'city',
          label: 'Cidade',
        },
        {
          field: 'district',
          label: 'Estado'
        },
        {
          field: 'add_info',
          label: 'Informações adicionais',
        },
        {
          field: 'tags',
          label: 'Tags',
        },
        {
          field: 'contacts_groups',
          label: 'Grupos de contatos',
        },
        {
          field: 'date_create',
          label: 'Data de Cadastro',
        },
        {
          field: 'type_create',
          label: 'Tipo de contato',
        },
        {
          field: 'portfolio',
          label: 'Encarteiramento',
        },
      ],
    };
  },
  props: {
    isModalTableActive: {
      type: Boolean,
      require: true,
    },
    errosFound: {
      type: Array,
      require: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
  computed: {
    getData() {
      if (this.errosFound) {
        return this.errosFound.map(m => {
          return {
            ...m.contact,
            index: m.index,
            errors: m.errors,
          };
        });
      } else {
        return [];
      }
    },
  },
};
</script>