<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false"> Contatos </hero-bar>
    <section class="section is-main-section">
      <b-message 
      title="Aviso!" 
      type="is-success" 
      has-icon icon="information" 
      :closable="true">
        <b>
         O formato da planilha para importação sofreu alteração, para seguir com o novo padrão faça o download do novo formato disponibilizado em: "Outras ações" > "Exportar Dados para CSV"
        </b>
      </b-message>
      <hr>
      <b-upload v-model="file" class="file-label" accept=".csv" required
        validationMessage="Por favor selecione um arquivo">
        <span class="file-cta is-primary">
          <b-icon class="file-icon" icon="upload"></b-icon>
          <span class="file-label">Importar CSV</span>
        </span>
        <span class="file-name" v-if="file">
          {{ file.name }}
        </span>
      </b-upload>
      <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
        <template #trigger="{ active }">
          <b-button label="Outras ações" :icon-right="active ? 'menu-up' : 'menu-down'" />
        </template>

        <b-dropdown-item v-if="isAdminOrSupervisor" aria-role="listitem" @click="exportData(allContacts)">Exportar dados
          para CSV</b-dropdown-item>
        <b-dropdown-item v-if="isAdminOrSupervisor" aria-role="listitem"
          @click="handleResetPrivacyTermsAllContacts()">Redefinir termos de privacidade aprovados</b-dropdown-item>
        <b-dropdown-item v-if="isAdminOrSupervisor" aria-role="listitem" @click="openConfirmSyncWppContacts">Sincronizar
          contatos do whatsapp (aparelho)</b-dropdown-item>
      </b-dropdown>
      <template v-if="file">
        <div>
          <hr>
          <section>
              <b-field>
                <b-checkbox v-model="keeptag">Manter as tags existentes</b-checkbox>
                  <b-tooltip label="Se esta opção estiver marcada, as tags existentes na planilha serão mantidas. Se estiver desmarcada, as novas tags substituirão as existentes"
                  multilined size="is-large" position="is-right">
                    <b-icon icon="help-circle" type="is-success" size="is-small"/>
                </b-tooltip>
              </b-field>
              <b-button v-if="(isAdminOrSupervisor || import_csv) && file" icon-right="file-check-outline" @click="validateCSV()" class="button is-info mb-2   is-full" :loading="isLoading">
                Validar
              </b-button>
              <b-button v-if="showErrors" @click="showErrorDetails" type="is-danger" outlined >
                Exibir erros
              </b-button>
              <br>
              <b-button v-if="isValidated" icon-right="upload" @click="importCSV()"
                class="button is-info mb-2 is-full" :loading="isLoading">
                Importar
              </b-button>
          </section>

          <section v-if="selectedOption === 'wallet'">
            <br>
            <p>Seção de importação de Encarteiramento</p>
          </section>
        </div>
      </template>

      <ModalInsertContact v-if="isComponentModalActive" v-on:addContact="searchForContact" v-on:closeModal="closeModal"
        v-on:updateContact="handleUpdateContact" :isComponentModalActive="isComponentModalActive"
        :contactUpdating="contactUpdating" :departments="allDepartments" :attendants="allAttendants"
        :contactAttendants="contactAttendants" :canLink='canLink' :contactTags="allContactTags" :tags="allEnabledTags" />
      <ModalConfirmSyncWppContacts :isModalConfirmActive="isModalConfirmActive" :settings="allTokensEnabled"
        v-on:closeModal="closeModal" v-on:sync="handleSyncWhatsappContacts" />
      <ModalTableContactsCSV v-if="isModalTableActive" :isModalTableActive="isModalTableActive" v-on:closeModal="closeModal" :errosFound="errors" />
      <b-loading v-if="fetchingContacts" :is-full-page="true" v-model="fetchingContacts" :can-cancel="false"></b-loading>
      <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="false">
      </b-loading>
      <ContactsList class="mt-2" v-else :isAdmin="getIsAdmin" v-on:removeContact="removeContact"
        v-on:updateContact="openUpdateContact" :canDelete="isAdminOrSupervisor" :contactDeletingId="contactDeleting" />
      <FabButton v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Novo contato" />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { mapActions, mapGetters } from 'vuex'
import ContactsList from '@/components/contacts/ContactsList.vue'
import FabButton from '@/components/FabButton.vue'
import ModalInsertContact from '@/components/modals/ModalInsertContact'
import { get, post } from '../utils/api'
import ModalConfirmSyncWppContacts from '@/components/modals/ModalConfirmSyncWppContacts.vue'
import exportExcel from '../exportExcel'
import mixins from '../utils/mixins'
import ModalTableContactsCSV from '@/components/modals/ModalTableContactsCSV.vue'

export default {
  name: 'Contacts',
  components: {
    HeroBar,
    TitleBar,
    ContactsList,
    FabButton,
    ModalInsertContact,
    ModalConfirmSyncWppContacts,
    ModalTableContactsCSV,
  },
  mixins: [mixins],
  data() {
    return {
      errors: [], 
      isModalTableActive: false,
      isValidated: false,
      isComponentModalActive: false,
      isModalConfirmActive: false,
      contactUpdating: null,
      contactAttendants: [],
      canLink: false,
      isAdminOrSupervisor: false,
      isExporting: false,
      file: null,
      isLoading: false,
      contactDeleting: 0,
      import_csv: false,
      active: false,
      selectedOption: 'contacts',
      showErrors: false,
      keeptag: true
    }
  },
  computed: {
    ...mapGetters([
      'allContacts',
      'fetchingContacts',
      'getIsAdmin',
      'allDepartments',
      'allAttendants',
      'allContactAttendants',
      'fetchingContactAttendants',
      'getIsSupervisorAllDepartments',
      'allEnabledTags',
      'allContactTags',
      'fetchingContactTags',
      'allTokensEnabled',
      'getUserId',
      'getAppName'
    ]),
    titleStack() {
      return ['Cadastros', 'Contatos']
    },
    isButtonDisabled() {
      return !this.import_csv
    }
  },
  mounted() {
    get(`/attendants/permissions/${this.getUserId}`).then((response) => {
      this.import_csv = response.data.import_csv
    })
  },
  methods: {
    ...mapActions([
      'addContact',
      'deleteContact',
      'syncWhatsappContacts',
      'updateContact',
      'fetchContactAttendants',
      'fetchAttendants',
      'fetchDepartments',
      'addContactAttendants',
      'fetchEnabledTags',
      'fetchContactTags',
      'addContactTags',
      'fetchTokens',
      'resetPrivacyTermsAllContacts',
      'fetchContacts'
    ]),
    async searchForContact(contact) {
      this.closeModal()
      const duplicateContact = await get(`contacts/findContact/${contact.info.phone}/null`)
      if (duplicateContact.data) {
        this.confirmDuplicateContact(duplicateContact.data, contact)
      } else {
        this.handleAddContact(contact)
      }
    },
    async handleAddContact(contact) {
      await this.addContact(contact.info)
      this.addContactAttendants(contact.links)
      this.addContactTags(contact.tags)
    },
    async handleResetPrivacyTermsAllContacts() {
      await this.resetPrivacyTermsAllContacts()
    },
    async handleAddClick() {
      this.isLoading = true;
      if (this.contactUpdating) await this.getContactAttendants(this.contactUpdating)
      if (this.contactUpdating) await this.getContactTags(this.contactUpdating)
      this.isLoading = false;
      this.isComponentModalActive = !this.isComponentModalActive
    },
    closeModal() {
      this.contactUpdating = null
      this.isComponentModalActive = false
      this.isModalConfirmActive = false
      this.isModalTableActive = false
    },
    confirmDuplicateContact(contact, newContact) {
      this.$buefy.dialog.confirm({
        title: 'Duplicar contato',
        message: `Já existe um contato com esse número!<br><br>Contato existente:<br>${contact.name} - ${contact.phone}<br><br>Contato a ser salvo:<br>${newContact.info.name} - ${newContact.info.phone}<br><br>Deseja <b>salvar</b> mesmo assim?`,
        confirmText: 'Salvar contato',
        type: 'is-danger',
        size: 'is-big',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.handleAddContact(newContact)
      })
    },
    async openConfirmSyncWppContacts() {
      await this.fetchTokens()
      this.isModalConfirmActive = !this.isModalConfirmActive
    },
    async handleSyncWhatsappContacts(data) {
      await this.syncWhatsappContacts(data)
        .then(async r => {
          this.showMessageOk('Sincronização de contatos do whatsapp', 'Sincronização de contatos do whatsapp realizada com sucesso!', this.reloadPage, this.reloadPage)
          
        })
        .catch(error => {
          this.showMessageOk('Erro ao sincronizar dados de contatos do whatsapp', 'Ocorreu um erro ao sincronizar dados de contatos do whatsapp. Recarregue a página, cheque a conexão do aparelho e tente novamente!')
          throw error
        })
    },
    async removeContact(contact) {
      try {
        this.contactDeleting = contact.id
        await this.deleteContact(contact)
        this.$buefy.toast.open('Contato excluido com sucesso!')
      } catch (error) {
        this.$buefy.toast.open(
          'Não é possível excluir este contato, possivelmente ele possui relacionamentos com mensagens e/ou tickets!'
        )
      } finally {
        this.contactDeleting = 0
      }
    },
    openUpdateContact(contact) {
      this.contactUpdating = contact
      this.handleAddClick()
    },
    async handleUpdateContact(contact) {
      this.closeModal()
      this.updateContact(contact.info).catch(() =>
        this.$buefy.dialog.alert({
          title: 'Erro',
          message:
            'Ocorreu um <b>erro</b> ao atualizar o contato. Recarregue a tela e tente novamente!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      )
      this.addContactAttendants(contact.links)
      await this.addContactTags(contact.tags)
    },
    async getContactAttendants(contact) {
      if (contact && contact.id) {
        try {
          await this.fetchContactAttendants(contact.id)
        } catch (error) {
          console.log(error)
        }
      }
    },
    async getContactTags(contact) {
      if (contact && contact.id) {
        try {
          await this.fetchContactTags(contact.id)
        } catch (error) {
          console.log(error)
        }
      }
    },
    setCanLink(canLink) {
      this.canLink = canLink
    },
    setIsAdminOrSupervisor(isAdminOrSupervisor) {
      this.isAdminOrSupervisor = isAdminOrSupervisor
    },
    async validateCSV() {
      this.isLoading = true
      const formData = new FormData()
      formData.append('file',this.file)
      await post('contacts/validation-csv', formData)
        .then((result) => {
          console.log(result.data.message)
          this.$buefy.snackbar.open({
          position: 'is-top',
          title: 'Validada com sucesso',
          message: result.data.message ?? 'Sua planilha foi validada com sucesso!',
          type: 'is-success',
          duration: 5000
        })
        this.isValidated = true
        this.isLoading = false
        })
        .catch(error => {
        if (error && error.response && error.response.data) {
          if(error.response.data.erros) {
            this.errors = error.response.data.erros 
            console.log(this.errors)
            this.showErrors = true
            this.$buefy.snackbar.open({
              message: 'Não foi possível importar sua planilha',
              type: 'is-warning',
              position: 'is-top',
              actionText: 'Erro',
              duration: 5000,
            })
          } else if (error.response.data.err) {
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'Sua planilha não encontra em conformidade com o padrão por favor validar a estrutra',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        } 
      } else {
        this.$buefy.toast.open({
          duration: 5000,
          message: `Não foi possível validar sua planilha, por favor tente novamente`,
          position: 'is-top',
          type: 'is-danger'
        })
        this.file = null
      }
      this.isValidated = false
      this.isLoading = false
    })
  },
    showErrorDetails() {
      this.isModalTableActive = true;
    }, 
    async importCSV() {
      this.isLoading = true
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('keeptag', this.keeptag)
      await post('/contacts/import-csv', formData)
        .then(function (result){
          console.log(result)
        })
        .catch(error => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Não foi possível validar sua planilha, por favor tente novamente`,
            position: 'is-top',
            type: 'is-danger'
          })
        })
        this.file = null
        this.isLoading = false
        this.fetchContacts()
    },
    async exportData() {
      this.isExporting = true
      try {
        const exportContacts = await get('contacts/export').then((response) => response.data)

        const contacts = exportContacts.map(contact => {
          const type = contact.contact_type === '1'
            ? 'cliente'
            : contact.contact_type === '2'
              ? 'produtor'
              : contact.contact_type === '3'
                ? 'corretor'
                : ''

          const tags = contact.contacts_tags.map(contactTag => contactTag.tag.title)

          const creationType = contact.creation_type === 'MAN'
            ? 'manual'
            : contact.creation_type === 'AUTO'
              ? 'automático'
              : contact.creation_type === 'SYNC'
                ? 'whatsapp'
                : contact.creation_type === 'CSV'
                  ? 'planilha'
                  : ''

          return {
            id: contact.id,
            name: contact.name,
            phone: contact.phone,
            cpf_cnpj: contact.cpf_cnpj,
            birthday: contact.birthday ? new Date(contact.birthday) : '',
            contact_type: type,
            email: contact.email,
            instagram_profile: contact.instagram_profile,
            address: contact.address,
            address_2: contact.address_2,
            neighborhood: contact.neighborhood,
            city: contact.city,
            district: contact.district,
            additional_info: contact.additional_info,
            tags: tags.join(', '),
            created_at: new Date(contact.created_at),
            creation_type: creationType,
            portfolio : contact.contacts_attendants.map(l => (`${l.attendant_id}:${l.department_id}`)).filter(l => l.length > 0).join(', ')
          }
        })

        const sheets = [
          {
            name: 'Contatos',
            headers: [
              { header: 'ID', key: 'id', width: 10 },
              { header: 'Nome', key: 'name', width: 20 },
              { header: 'Telefone', key: 'phone', width: 20 },
              { header: 'CPF/CNPJ', key: 'cpf_cnpj', width: 20 },
              { header: 'Data de nascimento (AAAA/MM/DD)', key: 'birthday', width: 20 },
              { header: 'Tipo de contato', key: 'contact_type', width: 20 },
              { header: 'Email', key: 'email', width: 20 },
              { header: 'Instagram', key: 'instagram_profile', width: 20 },
              { header: 'Endereço', key: 'address', width: 20 },
              { header: 'Complemento', key: 'address_2', width: 20 },
              { header: 'Bairro', key: 'neighborhood', width: 20 },
              { header: 'Cidade', key: 'city', width: 20 },
              { header: 'Estado', key: 'district', width: 20 },
              { header: 'Informações adicionais', key: 'additional_info', width: 20 },
              { header: 'Tags', key: 'tags', width: 20 },
              { header: 'Grupo de contatos', key: 'contacts_group', widith: 20},
              { header: 'Data de cadastro (Dados de exportação)', key: 'created_at', width: 20 },
              { header: 'Tipo de cadastro (Dados de exportação)', key: 'creation_type', width: 20 },
              { header: 'Encarteiramento' , key: 'portfolio' , width: 20}
            ],
            data: contacts
          }
        ]

        await exportExcel(`Contatos ${this.getAppName}`, sheets)

        this.isExporting = false
      } catch (error) {
        console.log(error)
        this.isExporting = false
        this.$buefy.toast.open('Não foi possível exportar sua lista de contatos')
      }
    }
  },
  created() {
    this.fetchAttendants()
    this.fetchDepartments()
    this.fetchEnabledTags()
    this.setCanLink(this.getIsAdmin || this.getIsSupervisorAllDepartments)
    this.setIsAdminOrSupervisor(this.getIsAdmin || this.getIsSupervisorAllDepartments)
  },
  watch: {
    allContactAttendants(newContactAttendants, oldContactAttendants) {
      this.contactAttendants = newContactAttendants
    },
    allContactTags(newContactTags, oldContactTags) {
      this.contactTags = newContactTags
    },
    allEnabledTags(newTags, oldTags) {
      this.tags = newTags
    },
    file() {
      this.isValidated = false
      this.showErrors = false
      this.errors = []
    }
  }
}
</script>
