<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">Grupos de contatos</hero-bar>
    <section class="section is-main-section">
      <ModalInsertContactGroup
        v-if="isComponentModalActive"
        v-on:addContactGroup="handleAddContact"
        v-on:closeModal="closeModal"
        v-on:updateContactGroup="handleUpdateContactGroup"
        :isComponentModalActive="isComponentModalActive"
        :groupUpdating="groupUpdating"
        :contacts="allContacts"
      />
      <b-loading
        v-if="fetchingContactsGroups"
        :is-full-page="true"
        v-model="fetchingContactsGroups"
        :can-cancel="false"
      ></b-loading>
      <ContactsGroupsList
        v-else
        :contactsGroups="allContactsGroups"
        :isAdmin="getIsAdmin"
        v-on:removeContactGroup="removeContactGroup"
        v-on:updateContactGroup="openUpdateContact"
        :groupDeletingId="groupDeletingId"
      />
      <FabButton
        v-on:addClick="handleAddClick"
        iconClass="fas fa-pen-fancy"
        title="Novo grupo"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { mapActions, mapGetters } from 'vuex'
import ContactsGroupsList from '@/components/contacts_groups/ContactsGroupsList.vue'
import FabButton from '@/components/FabButton.vue'
import ModalInsertContactGroup from '@/components/modals/ModalInsertContactGroup'

export default {
  name: 'ContactsGroups',
  components: {
    HeroBar,
    TitleBar,
    ContactsGroupsList,
    FabButton,
    ModalInsertContactGroup
  },
  data () {
    return {
      isComponentModalActive: false,
      groupUpdating: null,
      groupDeletingId: 0
    }
  },
  computed: {
    ...mapGetters(
      [
        'allContactsGroups',
        'fetchingContactsGroups',
        'getIsAdmin',
        'allContacts',
        'fetchingContacts'
      ]
    ),
    titleStack () {
      return ['Cadastros', 'Grupos de contatos']
    }
  },
  methods: {
    ...mapActions([
      'addContactGroup',
      'fetchContactsGroups',
      'deleteContactGroup',
      'syncWhatsappContacts',
      'updateContactGroup',
      'fetchContacts',
      'fetchContactGroupDetail'
    ]),
    handleAddContact (contact) {
      this.closeModal()
      this.addContactGroup(contact)
    },
    handleAddClick () {
      this.fetchContacts()
      this.isComponentModalActive = !this.isComponentModalActive
    },
    closeModal () {
      this.groupUpdating = null
      this.isComponentModalActive = false
    },
    async removeContactGroup (contact) {
      try {
        this.groupDeletingId = contact.id
        await this.deleteContactGroup(contact.id)
        this.$buefy.toast.open('Grupo excluido com sucesso!')
      } catch (error) {
        this.$buefy.toast.open(
          'Não é possível excluir este contato, possivelmente ele possui relacionamentos com mensagens e/ou tickets!'
        )
      } finally {
        this.groupDeletingId = 0
      }
    },
    async openUpdateContact (contact) {
      this.fetchContacts()
      this.groupUpdating = await this.fetchContactGroupDetail(contact.id)
      this.handleAddClick()
    },
    handleUpdateContactGroup (group) {
      this.closeModal()
      this.updateContactGroup(group).catch(() =>
        this.$buefy.dialog.alert({
          title: 'Erro',
          message:
            'Ocorreu um <b>erro</b> ao atualizar o grupo. Recarregue a tela e tente novamente!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      )
    }
  },
  created () {
    this.fetchContactsGroups()
  }
}
</script>
